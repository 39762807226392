<template>
  <SubNav class="subnav-position" :selected="'press'" :id="id" />
  <main>
    <form @submit.prevent="handleSubmit" class="shadow edit-form">
      <div class="float-end">
        <router-link :to="{ name: 'OfferingPress', params: { id: id } }"
          ><h4>&times;</h4></router-link
        >
      </div>
      <h6>Create a New Press Clipping</h6>
      <div class="form-outline mb-4">
        <label>Date</label>
        <input type="date" required v-model="date" class="form-control" />
      </div>
      <div class="form-outline mb-4">
        <label>Title</label>
        <input type="text" required v-model="title" class="form-control" />
      </div>
      <div class="form-outline mb-4">
        <label>Link (www.news.com)</label>
        <input type="text" required v-model="link" class="form-control" />
      </div>
      <div class="error">{{ formError }}</div>
      <div v-if="!isPending" class="row text-center">
        <div class="col">
          <button class="btn btn-primary">Create</button>
        </div>
        <div class="col">
          <router-link
            :to="{ name: 'OfferingPress', params: { id: id } }"
            class="btn btn-secondary"
          >
            Cancel
          </router-link>
        </div>
      </div>
      <div v-else>
        <button class="btn btn-secondary" disabled>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Creating...</span>
          </div>
        </button>
      </div>
    </form>
  </main>
  <Footer />
</template>

<script>
import SubNav from "@/components/navigation/SubNav.vue";
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import getUser from "@/composables/getUser.js";
import { timestamp } from "@/firebase/config";
import useCollection from "@/composables/useCollection";
import Footer from "@/components/navigation/Footer.vue";

export default {
  props: ["id"],
  components: { SubNav, Footer },
  setup(props) {
    const title = ref("");
    const date = ref("");
    const link = ref("");
    const isPending = ref(false);
    const formError = ref(null);
    const router = useRouter();
    const { user } = getUser();
    const { error, addDoc } = useCollection("offeringPress");

    const handleSubmit = async () => {
      try {
        isPending.value = true;
        formError.value = null;
        const ref = await addDoc({
          date: date.value,
          title: title.value,
          url: link.value,
          offeringId: props.id,
          issuerId: user.value.uid,
          createdAt: timestamp()
        });
        isPending.value = false;
        if (error.value) {
          formError.value = error.value;
        } else {
          router.push({
            name: "EditPress",
            params: { id: props.id, pressId: ref.id }
          });
        }
      } catch (err) {
        formError.value = "System Error: " + err.message;
      }
    };

    return { isPending, formError, title, date, link, handleSubmit };
  }
};
</script>

<style>
</style>